import axios from 'axios'

//ai问答地址
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_LLM_API
});

// 语音模型地址
export const audioClient = axios.create({
    baseURL: process.env.VUE_APP_LLM_API + "/api"
    // baseURL: 'http://222.85.202.67:666/api'
});

audioClient.interceptors.request.use((config) => {
    console.log("audioClient", config)
    return config
})


export const req = async (method, url, data = null, config = {}) => {
    try {
        const response = await apiClient({
            method,
            url,
            data,
            ...config,
        });
        return response.data;
    } catch (error) {
        console.error('错误信息:', error.response || error.message || error);
        throw error;
    }
};

export const AUDIO_SERVER = async (method, url, data = null, config = {}) => {
    try {
        const response = await audioClient({
            method,
            url,
            data,
            ...config,
        });
        return response.data;
    } catch (error) {
        console.error('错误信息:', error.response || error.message || error);
        throw error;
    }
};


