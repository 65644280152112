<template>
  <el-form
    ref="resumeForm"
    class="resumeForm"
    v-loading="loading"
    :model="showData"
    :rules="rules"
    label-width="80px"
    label-position="top"
  >
    <div class="formItemBox">
      <div class="formItemBox_top">
        <el-form-item label="姓名" prop="userName">
          <el-input
            v-model="showData.userName"
            placeholder="请输入姓名"
            clearable
            maxlength="12"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="showData.sex" placeholder="请选择性别">
            <el-option
              v-for="itemO in sexSelectList"
              :key="itemO.dictValue"
              :label="itemO.dictLabel"
              :value="itemO.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出生年月" prop="birthDate">
          <el-date-picker
            placeholder="请选择出生年月"
            v-model="showData.birthDate"
            type="date"
            format="yyyy-MM-dd"
            :picker-options="expireTimeOption"
            value-format="yyyy-MM-dd"
            @input="$forceUpdate()"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="想要干的工作" prop="expectedWork">
          <el-cascader
            v-model="showData.expectedWork"
            :placeholder="showData.expectedWork.join('/') || '请选择想要从事的工作'"
            :options="workTypeList"
            filterable
            :props="workTypeListOption"
            @change="changeWorkType"
            @clear="clearWorkType"
            ref="cascader"
            collapse-tags
            collapse-tags-tooltip
            :separator="'/'"
            icon="el-icon-caret-bottom"
            :class="[!showData.expectedWork.join('/') ? '' : 'placeholder']"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="想要在哪里工作" prop="workPlaceWant">
          <el-cascader
            :class="[showData.workPlaceWant.join('') ? 'placeholder' : '']"
            :placeholder="showData.workPlaceWant.join('') || '请选择想要去哪个地方工作'"
            v-model="showData.workPlaceWant"
            :options="placeData.areaList"
            :props="placeData.areaListOption"
            filterable
            :separator="''"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="期望薪资" prop="workSalary" ref="moneyFormItem" :class="['moneyFormItem']">
          <el-input v-model="showData.workSalary" @focus="moneyFocus" clearable :readonly="true"></el-input>
          <div class="editMoney" v-if="salaryData.showEditMoney">
            <div>
              <el-input
              v-model="auxData.minWorkSalarys"
              placeholder="请输入最小薪资"
              type="text"
              oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
            </div>
            <span>至</span>
            <div>
              <el-input
              v-model="auxData.maxWorkSalarys"
              placeholder="请输入最大薪资"
              type="text"
              oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              ></el-input>
            </div>
          </div>
          <div class="salaryBox" v-show="salaryData.moneyVisible">
            <div class="parentList">
              <div
                :class="['parentList-item', salaryData.moneyParentIndex === index ? 'active' : '']"
                v-for="(item, index) in salaryData.moneyListTab"
                :key="index"
                @click="changeParentTitleMoney(item, index)"
              >
                {{ item.dictLabel }}
              </div>
            </div>
            <div class="childList">
              <div
                class="childList-item"
                v-for="(item0, index0) in salaryData.moneyList"
                :key="index0"
                @click="selectMoney(item0)"
              >
                <div>{{ item0.dictLabel }}</div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="文化程度" prop="eduLevel">
          <el-select v-model="showData.eduLevel" placeholder="请选择你的学历">
            <el-option
              v-for="item in eduLevelList"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="毕业学校" prop="graduate">
          <el-input
            v-model="showData.graduate"
            placeholder="请输入你的毕业学校"
            maxlength="50"
            show-word-limit
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="技能">
          <el-input
            v-model="showData.csUserJobWantExtend.skill"
            placeholder="请输入个人技能"
            maxlength="50"
            show-word-limit
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="技能证书">
          <el-cascader
            :class="[!(auxData.certSecNames || auxData.certNames) ? '' : 'placeholder']"
            :placeholder="auxData.certSecNames || auxData.certNames || '请选择证书'"
            v-model="showData.skillsCertificate"
            :options="certificate.certNamesList"
            :props="certificate.certNamesOption"
            filterable
            clearable
            @change="changeCertNames"
          ></el-cascader>
        </el-form-item>
      </div>

      <div class="formItemBox_bottom">
        <div v-for="(item, index) in showData.csJobExperiences" :key="item.client_id" class="experienceItem">
          <el-form-item :label="`工作经历${index + 1}`">
            <template slot="label">
              <span>{{ `工作经历${index + 1}` }}</span>
              <!--  <el-button v-if="!item.preventDelete" class="deleteBtn" @click="e => deleteExperience(item, e)">删除</el-button> -->
              <!--  修复禅道bug#2064 【我的简历】工作经历删除按钮消失   -->
              <el-button v-if="showDeleteBtn" class="deleteBtn" @click="e => deleteExperience(item, e)">删除</el-button>
            </template>
          </el-form-item>
          <div class="experienceItem_first_line">
            <el-form-item :prop="`csJobExperiences[${index}].workplace`">
              <el-input
                v-model="item.workplace"
                placeholder="请输入你的工作单位"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item :prop="`csJobExperiences[${index}].job`">
              <el-input v-model="item.job" placeholder="请输入你的职位" maxlength="30" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :prop="`csJobExperiences[${index}].span`">
              <el-date-picker
                class="data-range"
                placeholder="请选择工作持续时间"
                v-model="item.span"
                :picker-options="expireTimeOption"
                type="daterange"
                format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="入职日期"
                end-placeholder="离职日期"
                value-format="yyyy-MM-dd"
                @input="$forceUpdate()"
              ></el-date-picker>
            </el-form-item>
          </div>

          <el-form-item :prop="`csJobExperiences[${index}].jobContent`">
            <el-input
              class="experienceItem_desc"
              v-model="item.jobContent"
              type="textarea"
              rows="5"
              resize="none"
              maxlength="2000"
              show-word-limit
              clearable
              placeholder="请输入自己以往的工作经历，如：具体负责什么工作，完成情况等描述"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item class="formItemBox_add_experience">
          <el-button class="addExperienceBtn" icon="el-icon-plus" @click="addExperience">新增工作经历</el-button>
        </el-form-item>
        <el-form-item label="其他要求" style="margin-top: 15px">
          <el-input
            class="otherNeeds"
            type="textarea"
            v-model="showData.csUserJobWantExtend.otherBenefitsRequired"
            resize="none"
            rows="4"
            maxlength="32"
            show-word-limit
            clearable
            placeholder="如有其他福利要求，可填写在此"
          ></el-input>
        </el-form-item>
        <el-form-item label="自我介绍" prop="descr"  style="margin-top: 15px">
          <el-input
            class="self-introduce"
            type="textarea"
            v-model="showData.descr"
            resize="vertical"
            rows="4"
            maxlength="2000"
            show-word-limit
            clearable
            placeholder="请简要介绍一下自己，比如兴趣爱好，性格，工作..."
          ></el-input>
        </el-form-item>

<!--        <el-form-item label="" prop="fileUploadpath" class="fileUploadpathStyle">-->
<!--            <div style="display: flex; align-items: center; margin-top: 20px;">-->
<!--                <span>上传附件：</span>-->
<!--                <el-upload-->
<!--                    class="avatar-uploader"-->
<!--                    :action="uploadUrl"-->
<!--                    :headers="{Authorization: 'Bearer ' + token}"-->
<!--                    :on-success="uploadSuccess"-->
<!--                    :before-upload="beforeUpload"-->
<!--                    accept=".docx"-->
<!--                    :show-file-list="false">-->
<!--                <div class="upload-btn">-->
<!--                    <div class="upload-area">-->
<!--                        <el-button size="small" type="primary" class="primaryBtn">上传附件简历</el-button>-->
<!--                        <div slot="tip" class="el-upload__tip">支持上传word文档（.docx格式文件）</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                </el-upload>-->
<!--            </div>-->
<!--            <div class="uploaded-area" v-if="showData.fileUploadpath">-->
<!--                <div class="uploaded-area-left" @click="showFileUploadpath">-->
<!--                    <img src="@/assets/resumeTemplateDownload/uploadFile.png" alt=""/>-->
<!--                    <span>{{ fileName }}</span>-->
<!--                </div>-->
<!--                <div class="uploaded-area-right" @click="deleteResume">删除</div>-->
<!--            </div>-->
<!--        </el-form-item>-->
      </div>
    </div>
    <div class="submit_box">
      <el-button class="save_btn" @click="saveResume">保存</el-button>
      <el-button class="generate_btn" @click="goToResumeTemplateDialog">生成简历模板</el-button>
    </div>
    <!-- 简历 -->
    <ResumeTemplate ref="ResumeTemplate" />
  </el-form>
</template>

<script>
import * as $api from "@/api/personalCenter";
import { handleTree } from "@/utils/StringUtils";
import { throwOnResponseFailure } from "@/utils/request/error";
import moment from "moment";
import { jobWantUploadAPI } from "@/api/personalCenter";
// 工作经历校验规则
const experienceRule = {
  workplace: { required: true, trigger: "blur", message: "请填写工作单位" },
  job: { required: true, trigger: "blur", message: "请填写工作职务" },
  jobContent: { required: true, trigger: "blur", message: "请填写工作经历" },
  span: {
    type: "array",
    required: true,
    trigger: "blur",
    validator: (rule, value, callback) => {
      if (value.length !== 2 || !value[0] || !value[1]) {
        callback("请填写工作持续时间");
      } else {
        callback();
      }
    },
  },
};

export default {
  name: "ResumeForm",
  data() {
    return {
      csJobExperiencesId: 1,
      showData: {
        // 姓名
        userName: "",
        // 性别
        sex: "",
        // 出生日期
        birthDate: "",
        // 期望岗位
        expectedWork: [],
        // 期望工作地点
        workPlaceWant: [],
        // 教育水平
        eduLevel: "",
        // 毕业学校
        graduate: "",
        // 期望薪水
        workSalary: "",
        // 工作经历
        csJobExperiences: [
            {}, // 默认显示1
        ],
        // 技能证书
        skillsCertificate: [],
        // 自我介绍
        descr: "",
        // 拓展：技能、其他要求
        csUserJobWantExtend: {
          skill: "",
          otherBenefitsRequired: "",
        },
        // 简历
        fileUploadpath: "",
      },
      // 辅助数据, 用于辅助数据展示
      auxData: {
        // 岗位一级 id
        types: "",
        // 岗位一级 名称
        typesName: "",
        // 兼容
        typeName: "",
        // 岗位二级 id
        workTypes: "",
        // 岗位二级 名称
        workTypesName: "",
        // 最低薪水
        minWorkSalarys: "",
        // 最高薪水
        maxWorkSalarys: "",
        // 薪水单位
        workSalarysUnit: "",
        // 证书一级 id
        certId: "",
        // 证书一级名称
        certNames: "",
        // 证书二级 id
        certSecId: "",
        // 证书二级名称
        certSecNames: "",
        // 简历
        fileUploadpath: "",
      },
      rules: {
        userName: { required: true, trigger: "blur", message: "请输入姓名" },
        sex: { required: true, trigger: "blur", message: "请选择性别" },
        birthDate: { required: true, trigger: "change", message: "请选择出生年月" },
        expectedWork: { type: "array", required: true, trigger: "change", message: "请选择想要干的工作" },
        workPlaceWant: { type: "array", required: true, trigger: "change", message: "请选择想要在哪里工作" },
        eduLevel: { required: true, trigger: "change", message: "请选择你的学历" },
        graduate: { required: true, trigger: "blur", message: "请输入你的毕业学校" },
        csJobExperiences: [],
        descr: { required: true, trigger: "blur", message: "请填写自我介绍" },
        workSalary: {
          required: true,
          trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
            console.log('value', value)
            if (!value) {
              callback("请填写薪资范围");
              return;
            }
            if (value === "自定义金额") {
              if (!this.auxData.minWorkSalarys || !this.auxData.maxWorkSalarys) {
                callback("请填写薪资范围");
                return;
              }
              if (Number(this.auxData.minWorkSalarys) > Number(this.auxData.maxWorkSalarys)) {
                callback("最小薪资不能大于最大薪资");
                return;
              }
            }
            callback();
          },
        },
      },
      // 性别列表
      sexSelectList: [],
      // 文化程度列表
      eduLevelList: [],
      // 岗位列表
      workTypeList: [],
      // 岗位列表项规则
      workTypeListOption: {
        value: "id",
        label: "dictName",
        children: "children",
      },
      // 工作地
      placeData: {
        // 工作地列表
        areaList: [],
        // 工作地列表项规则
        areaListOption: {
          value: "areaName",
          label: "areaName",
          children: "children",
        },
      },
      // 薪资
      salaryData: {
        // 是否显示自定义薪资输入框
        showEditMoney: false,
        // 是否显示薪资选择列表
        moneyVisible: false,
        // 薪资当前 tab 页列表
        moneyList: [],
        // 薪资 tab 列表
        moneyListTab: [],
        // 当前 tab 页
        moneyParentIndex: 0,
        // 点击外部隐藏薪资列表函数
        callback: null,
      },
      certificate: {
        certNamesList: [], // 资格证书
        certNamesListNode: [],
        certNamesOption: {
          value: "id",
          label: "dictName",
          children: "children",
        },
      },
      expireTimeOption: {
        // 出生日期
        disabledDate(date) {
          // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() >= Date.now();
        },
      },
      loading: true,
      resumeData: {},
      fileName: null,
      uploadUrl: '',
      token:''
    };
  },
  computed: {
    showDeleteBtn() {
      if (this.showData.csJobExperiences && this.showData.csJobExperiences instanceof Array) {
        return this.showData.csJobExperiences.length > 1
      }
      return false
    }
  },
  watch: {
      "showData.workSalary": function (newValue) {
      if (newValue.indexOf("~") !== -1) {
        // 处理：'5000 ~ 6000 元/月'
        const splits = newValue.split("~");
        this.auxData.minWorkSalarys = splits[0].trim();
        this.auxData.maxWorkSalarys = splits[1].trim().split(" ")[0].trim();
      }  else if (newValue === "面议") {
        this.auxData.minWorkSalarys = "-1";
        this.auxData.maxWorkSalarys = "-1";
      } else {
        if (this.auxData.minWorkSalarys === "-1") {
          this.auxData.minWorkSalarys = "";
          this.auxData.maxWorkSalarys = "";
        }
      }
      this.salaryData.showEditMoney = newValue === "自定义金额";
    },
  },
  mounted() {
    const salaryFn = e => {
      let moneyFormItem = document.getElementsByClassName("moneyFormItem");
      if (moneyFormItem && moneyFormItem.length) {
        let isSelf = moneyFormItem[0].contains(e.target);
        if (!isSelf) {
          this.salaryData.moneyVisible = false;
        }
      }
    };
    this.salaryData.callback = salaryFn;
    // 薪资弹窗
    this.$nextTick(() => {
      document.addEventListener("click", salaryFn);
    });
    this.$emit("onMounted");
    console.log(' this.$store.state',  this.$store.state)
    this.token = this.$store.state.user.token;
    console.log(' this.$store.state',  this.$store.state)
    this.uploadUrl = `/api/file/upload`
  },
  // 在组件生命周期结束时销毁
  beforeDestroy() {
    document.removeEventListener("click", this.salaryData.callback);
  },
  methods: {
    // 初始化表格数据
    async initForm(data) {
      this.resumeData = data;
      console.log('dta', data)
      this.loading = true;
      // 加载初始化相关数据
      try {
        await this.getDataFromDictType();
      } catch (e) {
        console.error("获取数据失败", e);
        this.loading = false;
      }

      this.transformDataToShow(data);
      this.loading = false;

      // 加载大数据
      try {
        await this.getMoneyList();
        await this.getAllWorkTypeDict();
        await this.getAreaList();
        await this.getAllCertDict();
      } catch (e) {
        console.error("获取数据失败", e);
      }
    },
    // 将数据转换成显示需要的数据
    transformDataToShow(data) {
      const get = (value, def = "") => {
        return !value ? def : value;
      };
      console.log('将数据转换成显示需要的数据',data);

      // 年龄
      this.showData.userName = get(data.userName);
      // 性别
      this.showData.sex = get(data.sex, "");
      // 出生日期
      this.showData.birthDate = data.birthDate ? moment(data.birthDate).format("YYYY-MM-DD") : "";

      // 期望岗位
      this.showData.expectedWork = data.typeName ? [data.typeName, data.workTypesName] : [];
      this.auxData.types = get(data.types);
      this.auxData.typeName = get(data.typeName);
      this.auxData.typesName = get(data.typesName);
      this.auxData.workTypes = get(data.workTypes);
      this.auxData.workTypesName = get(data.workTypesName);
      this.auxData.fileUploadpath = get(data.fileUploadpath);

      // 期望工作地点
      if (data.workPlaceWant && data.workPlaceWant !== "") {
        const items = data.workPlaceWant.split("/");
        this.showData.workPlaceWant.push(...items);
      }
      // 期望薪资
      this.auxData.minWorkSalarys = get(data.minWorkSalarys, "-1");
      this.auxData.maxWorkSalarys = get(data.maxWorkSalarys, "-1");
      this.auxData.workSalarysUnit = get(data.workSalarysUnit, "1");
      if (data.minWorkSalarys === "-1" || data.maxWorkSalarys === "-1" || (data.minWorkSalarys === '0' && data.maxWorkSalarys === '0')) {
        this.showData.workSalary = "面议";
      } else  if (data.minWorkSalarys && data.minWorkSalarys !== "-1") {
        this.showData.workSalary =
          data.minWorkSalarys + " ~ " + data.maxWorkSalarys + " " + this.getSalarySuffix(data.workSalarysUnit);
      } else {
        this.showData.workSalary = "";
        this.auxData.minWorkSalarys = get(data.minWorkSalarys, "");
        this.auxData.maxWorkSalarys = get(data.maxWorkSalarys, "");
      }

      // 教育水平
      this.showData.eduLevel = get(data.eduLevel, "");
      // 毕业院校
      this.showData.graduate = get(data.graduate, "");
      // 工作经历
      this.showData.csJobExperiences = get(data.csJobExperiences, []).map(item => {
        const res = {
          ...item,
          client_id: this.csJobExperiencesId++,
          span: [moment(item.entryTime).format("YYYY-MM-DD"), moment(item.exitTime).format("YYYY-MM-DD")],
        };
        this.rules.csJobExperiences.push({ ...experienceRule });
        return res;
       });
       // 没有工作经历的时候的时候 默认第一条展示
        if (this.showData.csJobExperiences.length == 0) {
            this.addExperience()
        } else {
            if(this.showData.csJobExperiences.length > 0) {
                this.showData.csJobExperiences[0].preventDelete = true;
            }
        }

      // 拓展：技能、其他要求
      if (data.csUserJobWantExtend) {
        this.showData.csUserJobWantExtend = {
          skill: get(data.csUserJobWantExtend.skill),
          otherBenefitsRequired: get(data.csUserJobWantExtend.otherBenefitsRequired),
        };
      }

      // 技能证书
      this.showData.skillsCertificate = [];
      if (data.certNames) {
        this.auxData.certNames = data.certNames;
      }
      !!data.certNames && (this.auxData.certNames = data.certNames);
      if (data.certSecNames) {
        !this.auxData.certNames && (this.auxData.certNames = data.certSecNames);
        this.auxData.certSecNames = data.certSecNames;
      }
      if (data.certId) {
        this.auxData.certId = data.certId;
        this.showData.skillsCertificate.push(data.certId);
      }
      if (data.certSecId) {
        this.auxData.certSecId = data.certSecId;
        this.showData.skillsCertificate.push(data.certSecId);
      }

      // 自我介绍
      this.showData.descr = get(data.descr, "");
      // 简历
      this.showData.fileUploadpath = get(data.fileUploadpath);
     // 张三_20240823142156A008.docx
      if (data.fileUploadpath) {
        this.fileName = data.fileUploadpath.split('_')[0] + '.docx'
      }
      // console.log(JSON.stringify(this.auxData, null, 2));
    },
    // 将显示数据转换成服务器所需数据
    transformDataToUpload() {
        const data = {
        // 姓名
        userName: this.showData.userName,
        // 性别
        sex: this.showData.sex,
        // 出生日期
        birthDate: moment(this.showData.birthDate).valueOf(),

        // 期望岗位
        types: this.auxData.types,
        typeName: this.auxData.typeName,
        typesName: this.auxData.typesName,
        workTypes: this.auxData.workTypes,
        workTypesName: this.auxData.workTypesName,

        // 期望工作地点
        workPlaceWant: this.showData.workPlaceWant.join(""),
        // 教育水平
        eduLevel: this.showData.eduLevel,
        // 毕业学校
        graduate: this.showData.graduate,

        // 期望薪水

        minWorkSalarys: this.auxData.minWorkSalarys,
        maxWorkSalarys: this.auxData.maxWorkSalarys,
        workSalarysUnit: this.auxData.workSalarysUnit,

        // 资格证书
        certId: this.auxData.certId,
        certSecId: this.auxData.certSecId,
        certNames: this.auxData.certNames,
        certSecNames: this.auxData.certSecNames,
        // 简历
        fileUploadpath : this.showData.fileUploadpath,
        // 自我介绍
        descr: this.showData.descr,
        // 工作经历
        csJobExperiences: this.showData.csJobExperiences.map(item => {
          const data = JSON.parse(JSON.stringify(item));
          Object.hasOwn(data, "span") && delete data.span;
          Object.hasOwn(data, "client_id") && delete data.client_id;
          Object.hasOwn(data, "preventDelete") && delete data.preventDelete;
          if (item.span && item.span.length > 1) {
            data.entryTime = moment(item.span[0]).valueOf();
            data.exitTime = moment(item.span[1]).valueOf();
          }
          return data;
        }),
        // 拓展：技能、其他要求
        csUserJobWantExtend: {
          skill: this.showData.csUserJobWantExtend.skill,
          otherBenefitsRequired: this.showData.csUserJobWantExtend.otherBenefitsRequired,
        },
        };
        console.log('this.将显示数据转换成服务器所需数据', data)
        return data;
    },

    /**
     * 数据加载
     */
    // 根据字典值获取数据，包括 性别列表、文化程度列表、薪水tab列表
    async getDataFromDictType() {
      const fields = [
        "sys_user_sex", // 性别列表
        "dispatch_educational", // 文化程度列表
        "crm_pro_unit", // 薪水tab列表
      ];
      const res = await $api.dictTypeAPI({ dictType: fields.join(",") });
      throwOnResponseFailure(res);

      // 数据分类
      const sexSelectList = [];
      const eduLevelList = [];
      let moneyListTab = [];
      res.data.forEach(item => {
        switch (item.dictType) {
          case fields[0]:
            sexSelectList.push(item);
            break;
          case fields[1]:
            eduLevelList.push(item);
            break;
          case fields[2]:
            moneyListTab.push(item);
            break;
        }
      });

      // 处理薪资后缀
      let suffixList = [
        { name: "元/件", dictType: "crm_salary_type_to_piece" },
        { name: "元/时", dictType: "crm_salary_type_to_hour" },
        { name: "元/平方", dictType: "crm_salary_type_to_sq" },
        { name: "元/天", dictType: "crm_salary_type_to_day" },
        { name: "元/月", dictType: "crm_salary_type" },
      ];
      moneyListTab = moneyListTab.filter(item => item.dictLabel !== "其他");
      moneyListTab.forEach(item => {
        const suffix = suffixList.find(suffix => suffix.name === item.dictLabel);
        item.listDictType = suffix.dictType || "";
      });

      this.sexSelectList = sexSelectList;
      console.log('学历======',eduLevelList)
      this.eduLevelList = eduLevelList.filter(item => item.dictValue !== '0');
      this.salaryData.moneyListTab = moneyListTab;
    },
    // 获取工种
    async getAllWorkTypeDict() {
      try {
        const res = await $api.getAllWorkTypeDictAPI({});
        throwOnResponseFailure(res);
        // console.log("获取工种列表",res)
        const { data } = res;
        this.workTypeListNode = data.rows;
        this.workTypeList = handleTree(JSON.parse(JSON.stringify(data.rows)));
      } catch (e) {
        console.error("获取工种列表数据失败!", e);
      }
    },
    // 获取地区
    async getAreaList() {
      try {
        const res = await $api.getAreaListAPI({});
        throwOnResponseFailure(res);
        // console.log("获取地区列表",res)
        const { data } = res;
        this.placeData.areaList = handleTree(data.rows);
      } catch (e) {
        console.error("获取地区列表数据失败!", e);
      }
    },
    // 获取证书列表
    async getAllCertDict() {
      try {
        const res = await $api.getAllCertDictAPI({});
        throwOnResponseFailure(res);
        // console.log("获取证书列表",res)
        const { data } = res;
        this.certificate.certNamesListNode = data.rows;
        this.certificate.certNamesList = handleTree(JSON.parse(JSON.stringify(data.rows)));
      } catch (e) {
        console.error("获取证书列表数据失败!", e);
      }
    },
    // 获取所有tab页的薪水列表
    async getMoneyList() {
      try {
        const res = await $api.dictTypeAPI({
          dictType: this.salaryData.moneyListTab[this.salaryData.moneyParentIndex].listDictType,
        });
        throwOnResponseFailure(res);
        // console.log("获取薪水列表",res);
        this.salaryData.moneyList = res.data;
        this.salaryData.moneyList.forEach(money => {
          let a = this.salaryData.moneyListTab.find(tab => money.dictLabel.indexOf(tab.dictLabel) > -1);
          if (a) {
            money.unit = a;
          }
          money.dictLabel = money.dictLabel.replace("(", "").replace(")", "");
        });
      } catch (e) {
        console.error("获取薪水列表数据失败!", e);
      }
    },

    /**
     * 工具类
     */
    // 获取金额后缀
    getSalarySuffix(salaryUnit) {
      return this.salaryData.moneyListTab.find(item => item.dictValue === salaryUnit)?.dictLabel || "";
    },

    /**
     * view function
     * @param value
     */
    // 工种
    changeWorkType(value) {
      this.auxData.types = value[0];
      this.auxData.workTypes = value[1];
      this.workTypeListNode.forEach(item => {
        if (item.id === value[1]) {
          this.auxData.workTypesName = item.dictName;
        }
      });
    },
    // 清空工种数据
    clearWorkType() {},
    // 设置薪水列表显示
    moneyFocus() {
      this.salaryData.moneyVisible = true;
    },
    // 处理薪水选择更改
      selectMoney(item) {
      this.showData.workSalary = item.dictLabel;
      this.salaryData.moneyVisible = false;
        if (item.dictLabel.indexOf('以上') != -1) {
            // 薪资是  元以上的情况
            const splits = item.dictValue.split("-");
            this.auxData.minWorkSalarys = splits[0].trim();
            this.auxData.maxWorkSalarys = splits[1].trim().split(" ")[0].trim();
        }
      if (item.unit) {
        this.auxData.workSalarysUnit = item.unit.dictValue;
      }
    },
    // 切换薪资待遇头部
    changeParentTitleMoney(item, index) {
        this.salaryData.moneyParentIndex = index;
        this.auxData.workSalarysUnit = item.dictValue   // 薪资单位
        this.getMoneyList();
    },
    // 资格证书
      changeCertNames(value) {
       if (value && value.length > 0) {
        // 保存接口分别要  第一 第二级id  和名称
        this.auxData.certId = value[0];
        this.auxData.certSecId = value[1];
        this.certificate.certNamesListNode.forEach(item => {
          if (item.id === value[0]) {
            this.auxData.certNames = item.dictName;
          }
          if (item.id === value[1]) {
            this.auxData.certSecNames = item.dictName;
          }
        });
      } else {
          this.auxData.certId = "";
          this.auxData.certSecId = ""
          this.auxData.certNames = ""
          this.auxData.certSecNames = ""
          this.showData.skillsCertificate = []
      }
    },
    // 添加一项新的工作经历
    addExperience() {
      this.rules.csJobExperiences.push({ ...experienceRule });
      this.showData.csJobExperiences.push({
        client_id: this.csJobExperiencesId++,
        workplace: "",
        job: "",
        span: ["", ""],
        jobContent: "",
      });
    },
    // 删除指定工作经历项
    deleteExperience(item, e) {
      const rect = e.target.getBoundingClientRect();
      if (e.x < rect.x || e.y < rect.y) {
        return;
      }
      const index = this.showData.csJobExperiences.findIndex(i => i.client_id === item.client_id);
      if (index !== -1) {
        this.showData.csJobExperiences.splice(index, 1);
        this.rules.csJobExperiences.pop();
      }
    },
    // 将本地更改数据合成为服务器所需数据
    convertDataFromInput() {
      const transformData = this.transformDataToUpload();
      const data = {
        ...JSON.parse(JSON.stringify(this.resumeData)),
        ...JSON.parse(JSON.stringify(transformData)),
        csUserJobWantExtend: {
          ...JSON.parse(JSON.stringify(this.resumeData.csUserJobWantExtend || {})),
          ...JSON.parse(JSON.stringify(transformData.csUserJobWantExtend)),
          mobile: this.resumeData.csUserJobWantExtend?.mobile || this.$store.state.user.userInfo.phonenumber,
        },
        mobile: this.resumeData.mobile || this.$store.state.user.userInfo.phonenumber,
      };
      // 打印转换成服务器所需格式后的数据
      // console.log("convertDataFromInput",JSON.stringify(data, null, 2));
      return data;
    },
    // 保存简历更改
    async saveResume() {
      try {
        const validate = await this.$refs.resumeForm.validate();
        if (!validate) return null;
      } catch (e) {
        this.$message.warning("请检查表单填写是否正确!");
        return null;
      }
      try {
        const data = this.convertDataFromInput();
        const res = await jobWantUploadAPI(data);
        throwOnResponseFailure(res);
        console.log('res', res)
        this.$emit("save");
        this.$message({
          message: "简历更新成功!",
          type: "success",
          duration: 1500,
          customClass: "zZindex",
        });
        return data;
      } catch (e) {
        console.error("更新简历失败", e);
        this.$message.error("简历更新失败!");
        return null;
      }
    },
    // 打开简历模板页面
    async goToResumeTemplateDialog() {
    //   const data = await this.saveResume();
        //   this.$emit("goToResumeTemplateDialog", data);
        this.$refs.ResumeTemplate.getDetail({ userJonWantId : this.resumeData.id }, '简历模板')
    },
    // 简历上传
    beforeUpload(file) {
      const isDocx = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      const isValid = isDocx && file.name.endsWith('.docx');
      if (!isValid) {
        this.$message.error('文件类型必须为 .docx 格式!');
        return false;
      }
      this.fileName = file.name
      // 如果验证通过，返回 true 或者直接不返回任何值
      return true;
    },
    uploadSuccess(res) {
        this.showData.fileUploadpath = res.data.url
        this.$forceUpdate()
    },
    // 删除简历
    deleteResume() {
        this.showData.fileUploadpath = ''
        this.fileName = ''
    },
    // 显示简历
    showFileUploadpath() {
        window.open(this.showData.fileUploadpath)
        // Office Online 预览 URL
        // const previewUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(this.showData.fileUploadpath)}`;
        // window.open(previewUrl, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.resumeForm {
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  justify-content: center;
  .fileUploadpathStyle {// 上传简历
    .avatar-uploader {
        .primaryBtn {
            margin-left: 5PX;
            border: 1px solid red;
            background: #3075FF;
            border-radius: 4px;
            border-color: #3075FF;
        }
        .el-upload__tip{
            font-weight: 400;
            font-size: 14px;
            color: #676F7B;
            margin-left: 6px;
        }
        .upload-btn{
            display: flex;
            flex-direction: column;
            .upload-area{
                display: flex;
                width: 100%;
            }
        }
    }
    .uploaded-area{
            margin-top: 15px;
            display: flex;
            align-items: flex-end;
            .uploaded-area-left{
                // width: 100%;
                min-width: 300px;
                background: #F0F5FB;
                border-radius: 4px;
                display: flex;
                align-items: center;
                padding: 14px 30px 14px 14px;
                cursor: pointer;
                img{
                    width: 56px;
                    height: 56px;
                }
                span{
                    margin-left: 6px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #303133;
                }
            }
            .uploaded-area-right{
                min-width: 30px;
                font-weight: 400;
                font-size: 14px;
                color: #E21A1A;
                margin-left: 5px;
                cursor: pointer;
            }
        }
  }
  .placeholder {
    .el-input__inner::placeholder {
      color: #333 !important;
    }
  }
  .el-input--suffix {
    .el-input__inner {
        padding-right: 67px;
    }
  }

  .el-textarea {
    border-radius: 4px;
    padding: 20px;
    .el-input__count {
      right: 40px;
      bottom: 5px;
    }
  }
  .el-textarea__inner {
    min-height: 300px;
    border-radius: 0;
  }

  .formItemBox {
    width: 100%;
    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: max-content;
    grid-row-gap: 35px;
    .formItemBox_top {
      width: 100%;
      display: grid;
      grid-template-columns: 328px 328px 328px;
      grid-template-rows: max-content;
      grid-column-gap: 58px;
      grid-row-gap: 35px;
    }
    .formItemBox_bottom {
      width: 100%;
      //display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: max-content;
      grid-row-gap: 23px;

      .el-form-item__content {
        line-height: normal;
      }

      .data-range {
        width: 100%;
      }
    }

    .self-introduce,
    .otherNeeds {
      width: 100%;
      padding-left: 0;
      padding-bottom: 0;
      padding-top: 0;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .el-form-item:nth-child(11) {
      grid-column: 1 / 4;
      width: 100%;
    }

    .columns1 {
      grid-column: 1 / 2;
      /* 第一个元素独占第一列 */
    }

    .el-checkbox__inner {
      margin-top: -5px;
    }

    // 自定义金额
    .editMoney {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .el-input {
        width: 150px;
      }
      .el-input__inner {
        height: 40px !important;
        line-height: 40px !important;
        border: 1px solid #bebebe;
      }
    }
  }

  .submit_box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 43px;
    margin-bottom: 69px !important;

    .save_btn {
      width: 168px;
      height: 50px;
      background: #236ce0;
      border-radius: 5px;
      color: #ffffff;
      margin-right: 15px;
    }
    .generate_btn {
      width: 168px;
      height: 50px;
      background: #52c4a4;
      border-radius: 5px;
      color: #ffffff;
      margin-left: 15px;
    }
  }

  // 取消 发布按钮
  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 19px;
    margin-bottom: 48px;

    span {
      margin-right: 30px;
      width: 168px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      background: #236ce0;
      border-radius: 5px;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &.cancle {
        border: 1px solid rgba(157, 157, 157, 0.8);
        color: #333;
        background: #fff;
      }
    }
  }

  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label::after {
    content: "*";
    color: #ff0000;
    margin-left: 4px;
  }

  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label::before {
    display: none;
  }

  .el-select__caret {
    color: #4575c2;
    margin-right: 4px;
  }

  .el-form-item__label {
    line-height: normal;
    font-size: 16px;
    line-height: 22px !important;
    color: #555;
    position: relative;
  }

  .el-input {
    width: 328px;

    ::v-deep.el-input__inner {
      height: 56px !important;
      line-height: 56px !important;
      border: 1px solid #bebebe;
    }
  }

  .el-select {
    width: 328px;
  }

  .experienceItem {
    margin-bottom: 15px;
    .el-form-item__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .deleteBtn {
      width: 44px;
      height: 24px;
      border-radius: 3px;
      border: 1px solid #f45954;
      color: #f45954;
      background: transparent;
      padding: 0;
    }

    .experienceItem_first_line {
      display: grid;
      grid-template-columns: 328px 328px 328px;
      grid-template-rows: max-content;
      grid-column-gap: 58px;
    }

    .experienceItem_desc {
      width: 100%;
      padding-left: 0;
      padding-bottom: 0;
    }
  }

  .formItemBox_add_experience {
    width: 100%;
    .el-form-item__content {
      display: flex;
      justify-content: center;
    }

    // 添加工作经历按钮
    .addExperienceBtn {
      width: 128px;
      height: 36px;
      line-height: 36px;
      padding: 0;
      background: #236ce0;
      border-radius: 4px;
      color: #ffffff;
      grid-column: 1/3;

      .el-icon-plus {
        margin-left: -10px;
      }
    }
  }
}

.salaryBox {
  position: absolute;
  top: 50px;
  left: -25px;
  z-index: 55;
  width: 340px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  .parentList {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    .parentList-item {
      font-size: 14px;
      // color: #1A67E2;
      &.active {
        font-weight: bold;
        color: #1a67e2;
      }
    }
  }
  .childList {
    .childList-item {
      cursor: pointer;
      font-size: 14px;
      line-height: 40px;
      height: 40px;
      &:hover {
        background: rgba(242, 243, 247, 1);
        color: #333;
      }
    }
  }
}
</style>
