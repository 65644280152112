import request from '@/utils/request'

/**
 * 获取热门岗位列表
 * @param params
 */
export function jobApplyListApi(params) {
    return request.get('/api/crm/web/user/own/jobApply/list', {shouldEncrypt:false,shouldDecrypt:true,params})
}

export function jobApplyListAIApi(params) {
    return request.get('/api/crm/web/user/ai/own/jobApply/list', {shouldEncrypt:false,shouldDecrypt:true,params})
}
/**
 * 获取个人简历
 * @param params
 */
export function getResumeApi(params) {
    return request.get('/api/crm/web/jobWant/getUserJobWant', {shouldEncrypt:false,shouldDecrypt:true,params})
}

/**
 * 获取HR认证的企业信息
 * @param params
 */
export function getHRCompanyApi(params) {
    return request.get('/api/crm/web/companyInfo/getCompanyInfo', {shouldEncrypt:false,shouldDecrypt:true,params})
}
/**
 * 获取HR发布的招工信息 列表
 * @param params
 */
export function hrSendWorkApi(params) {
    return request.get('/api/crm/web/user/own/workWant/list', {shouldEncrypt:false,shouldDecrypt:true,params})
}

/**
 * 获取HR发布的招工信息 列表
 * @param id
 */
export function questionNumApi(id) {
    return request.get('/api/crm/web/workWant/getConfigInterviewQueNum/' + id, {shouldEncrypt:false,shouldDecrypt:true})
}

/**
 * 获取测评类型
 * @param id
 */
export function getExamTypeApi(id) {
    return request.get('/api/crm/web/interview/eval/check/' + id, {shouldEncrypt:false,shouldDecrypt:true})
}


/**
 * 保存面试视频记录 保存
 * @param data
 */
export function saveInterViewVideoApi(data) {
    return request.post('/api/crm/web/interview/save',data, {shouldEncrypt:true,shouldDecrypt:false})
}
// 更新面试视频信息
export function saveInterViewVideoUpdateApi(data) {
    return request.post('/api/crm/web/interview/update',data, {shouldEncrypt:true,shouldDecrypt:false})
}
export function uploadFileApi(data) {
    return request.post(
        '/api/file/upload/files',
        data,
        {shouldEncrypt:false,shouldDecrypt:false, noOtherData: true,headers:{"Content-Type": "multipart/form-data"}})
}

/**
 * 获取问题
 * */
export function getQuestionApi(data) {
    return request.post(
        '/llm-api/api/llm_interface/interview/question',
        data,
        {shouldEncrypt:false,shouldDecrypt:false, noOtherData: true})
}

/**
 * 获取文字转语音并播放
 * */
export function text2audioApi(data) {
    return request.post(
        '/qps/tools/text2audio',
        data,
        {shouldEncrypt:false,shouldDecrypt:false,noOtherData: true,headers:{"Content-Type": "multipart/form-data"}})
}

/**
 * 语音转文字
 * */
export function audio2textApi(data) {
    return request.post(
        '/qps/tools/audio2text',
        data,
        {shouldEncrypt:false,shouldDecrypt:false,noOtherData: true,headers:{"Content-Type": "multipart/form-data"}})
}

/**
 * 检查面试总结传参数据
 * */
export function interviewSummary(data) {
    return request.post(
        '/qps/interview/summary',
        data,
        {shouldEncrypt:false,shouldDecrypt:false,noOtherData: true})
}

/**
 * 获取单次评估的结果
 * */
export function interviewEvaluation(data) {
    return request.post(
        '/qps/interview/evaluation',
        data,
        {shouldEncrypt:false,shouldDecrypt:false,noOtherData: true})
}

/**
 * 视频转码接口
 * */
export function videoTranscodingApi(data) {
    return request.post(
        '/api/file/transCode',
        data,
        {shouldEncrypt:false,shouldDecrypt:false})
}



